import React from 'react'
import loadable from '@loadable/component'
import Animate from 'react-animate-on-scroll'

import {
  SiteIconStopWatch,
  SiteLight,
} from 'components/base/Icon/styles'

import heroVideoMp4 from 'assets/video/home-hero-video.mp4'
import heroVideoWebm from 'assets/video/home-hero-video.webm'
import heroVideoPoster from 'assets/video/home-hero-video-poster.jpg'
import heroImgMobile from 'assets/video/home-hero-img-mobile.jpg'

import Content, { Theme as ContentTheme } from 'components/base/Content'
import Title, { Level as TitleLevel } from 'components/base/Title'
import Link, { Theme as LinkTheme } from 'components/base/Link'
import Paragraph, { Theme as ParagraphTheme } from 'components/base/Paragraph'
import StartQuoteButton from 'components/base/StartQuoteButton'
import SectionRule from 'components/base/SectionRule/styles'
import AskQuestion, {
  Size as AskQuestionSize,
  Color as AskQuestionColor,
} from 'components/blocks/AskQuestion'
import { Theme as PageHeroTheme } from 'components/blocks/PageHero'

import {
  PageHeroFooter,
  PageHeroFooterContainer,
} from './styles'

const PageHero = loadable(() => import('../../components/blocks/PageHero').catch(() => () => null))
const PageHeroTitle = loadable(() => import('../../components/blocks/PageHero/Title').catch(() => () => null))
const FeaturedProducts = loadable(() => import('../../components/blocks/FeaturedProducts').catch(() => () => null))
const FeaturedCarriers = loadable(() => import('../../components/blocks/FeaturedCarriers').catch(() => () => null))
const CtaColor = loadable(() => import('../../components/blocks/CtaColor').catch(() => () => null))
const HeaderMeta = loadable(() => import('../../components/HeaderMeta').catch(() => () => null))

const HomeScreen: React.FC = (): React.ReactElement => (
  <>
    <HeaderMeta
      title='Insurance Lounge | Professional Insurance Agency'
      description='Insurance Lounge helps you compare all types of insurance with our no-commission licensed insurance professionals. Trust our team. Let’s work together!'
    />
    <PageHero
      theme={PageHeroTheme.video}
      imgMobile={heroImgMobile}
      video={[
        heroVideoMp4,
        heroVideoWebm,
      ]}
      videoPoster={heroVideoPoster}
    >
      <PageHeroTitle>
        Your Insurance Concierge
      </PageHeroTitle>
      <PageHeroFooter>
        <Animate animateIn='fadeInUp' animateOnce={true}>
          <PageHeroFooterContainer>
            <SiteIconStopWatch className='icon' />
            <h3>Got a Minute?</h3>
            <span>Shop, compare, and buy from top carriers.</span>
            <StartQuoteButton
              trackingInfo={{
                button_position: 'middle',
                page_category: 'homepage',
              }}
            />
          </PageHeroFooterContainer>
        </Animate>
      </PageHeroFooter>
    </PageHero>
    <Content margined={true}>
      <FeaturedProducts
        header={<Title level={TitleLevel.divThatLooksLikeH1}>It’s All Here</Title>}
        footer={<Link theme={LinkTheme.button} to='/products/'>Our Products</Link>}
      />
    </Content>
    <FeaturedCarriers />
    <CtaColor
      title='Not One Size Fits All'
      icon={<SiteLight />}
      linkUrl='about-us'
      linkTitle='Our Company'
    >
      <Content short={true} center={true} theme={ContentTheme.paddedBelow}>
        <Paragraph theme={ParagraphTheme.large}>
          Finding an insurance plan that is right for you and your budget can be challenging. At Insurance Lounge, we can help you compare home, auto, life, health, and more insurance policies to find the one that works best for you.
          Our experts help make the insurance purchasing experience easy and fun. Whether you’re looking to help your teenager get their first car insurance plan or you’re hoping to start retirement assistance, Insurance Lounge has you covered.
        </Paragraph>
      </Content>
      <Content short={true} center={true}>
        <Title level={TitleLevel.h2ThatLooksLikeH3}>Our Insurance Products</Title>
        <Paragraph>
          Our team is dedicated to being transparent with our customers when navigating the multiple types of insurance options. <Link to='/products/auto-insurance'>Auto</Link>, <Link to='/products/medicare-insurance'>Medicare</Link>, and <Link to='/products/business-insurance'>Business insurance</Link> are only the beginning of the major <Link to='/products'>insurance categories</Link> we cover at Insurance Lounge.
          We offer personalized recommendations and help guide you through the process, making your decision as effective and stress-free as possible. Think of us as a one-stop-shop for all your insurance needs.
        </Paragraph>
        <SectionRule />
        <Title level={TitleLevel.h2ThatLooksLikeH3}>What Makes Insurance Lounge Different</Title>
        <Paragraph>
          Insurance Lounge offers great plans in all major insurance categories, and—as a company—we are committed to bringing simplicity back to insurance.
          Our focus is on technology and how it can make the insurance shopping and buying experience efficient, fun, convenient, and personalized.
          As insurance is one of the most important things you can buy to help ensure the safety and protection of yourself, your family, and your assets, we know that you have a lot on your mind when selecting a policy.
          At Insurance Lounge, we will compare rates across numerous providers to find the best rate and coverage.
        </Paragraph>
        <SectionRule />
        <Title level={TitleLevel.h2ThatLooksLikeH3}>A Custom Approach</Title>
        <Paragraph>
          At Insurance Lounge, we offer many ways to shop for, purchase, and manage insurance, making the process efficient and straightforward.
          Our no commission licensed <Link to='/about-us/our-team'>insurance professionals</Link> have done the research to make our offerings unique. We’ll provide recommendations based on your situation and find you the best coverage and price catered to you.
          Our team can work with you online, over the phone, or in-person at any of our storefronts in <Link to='/locations/storefronts/beaverton'>Beaverton</Link>, <Link to='/locations/storefronts/grants-pass'>Grants Pass</Link>, <Link to='/locations/storefronts/eugene'>Eugene</Link>, <Link to='/locations/storefronts/medford'>Medford</Link>, or <Link to='/locations/storefronts/las-vegas'>Las Vegas</Link>.
        </Paragraph>
        <SectionRule />
        <Title level={TitleLevel.h2ThatLooksLikeH3}>Quote & Buy Online</Title>
        <Paragraph>
          If speaking to our team at our storefront locations is not an option for you, we offer accessible online portals that are easy for anyone to use. We encourage our customers to quote, compare, and buy insurance online to find the best fit for their finances.
        </Paragraph>
        <Paragraph>
          Whether you’re a young family, Medicare-age, or someone who simply needs insurance, Insurance Lounge is a no-commission agency with a priority on finding the right coverage for your family’s needs. <Link to='/quote/new'>Get a quote</Link> today in the way most convenient for you, whether in-person, online, or by calling our team at 1-800-211-8660.
        </Paragraph>
      </Content>
    </CtaColor>
    <AskQuestion title='We’re Here, When You Need Us' color={AskQuestionColor.black} size={AskQuestionSize.large}>
      Online, over the phone, or in person, we always have one of our no
      commission Insurance Concierges<sup>®</sup> ready to help.
    </AskQuestion>
  </>
)

export default HomeScreen
