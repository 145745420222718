import React from 'react'

import Link, { Theme as LinkTheme } from 'components/base/Link'

import {
  events,
  useGoogleTagManager,
} from 'hooks/useGoogleTagManager'

import type { StartQuoteButtonProps } from './types'

const StartQuoteButton = ({
  children = 'Quote & Buy',
  showExternalIcon = false,
  to = '/quote/new',
  trackingInfo,
}: StartQuoteButtonProps) => {
  const { pushDataLayerEvent } = useGoogleTagManager()

  return (
    <Link
      onClick={() => pushDataLayerEvent({
        ...events.cta_get_quote,
        ...trackingInfo,
      })}
      showExternalIcon={showExternalIcon}
      theme={LinkTheme.button}
      to={to}
    >
      {children}
    </Link>
  )
}

export default StartQuoteButton

export * from './types'
